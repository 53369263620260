export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Apps',
    route: 'apps',
    icon: 'GridIcon',
  },
  {
    header: 'Tools',
    class: 'sidebar-follow-text',
  },
  {
    title: 'API Docs',
    icon: 'FileIcon',
    href: 'https://api-docs.brime.tv/',
  },
  {
    title: 'SDKs',
    route: 'sdk',
    icon: 'BoxIcon',
  },
  {
    title: 'GitHub',
    icon: 'GithubIcon',
    href: 'https://github.com/brimelive',
  },
]
